import { Dispatch, SetStateAction } from 'react';

import { GuidelineData } from './domain';

type Props = {
    guidelineListData: GuidelineData[];
    selectedGuideline: GuidelineData | null;
    setSelectedGuideline: Dispatch<SetStateAction<GuidelineData | null>>;
};

export const GuidelinesTable = ({ guidelineListData, selectedGuideline, setSelectedGuideline }: Props) => {
    return (
        <div className="flex flex-col rounded-lg border-2 border-blue-100">
            {guidelineListData.map(x => (
                <div
                    className="cursor-pointer border border-blue-100"
                    key={x.id}
                    onClick={() => setSelectedGuideline(x)}
                >
                    {x.id === selectedGuideline?.id ? (
                        <div className="bg-blue-100 py-3 pl-2 pr-32"> {x.title}</div>
                    ) : (
                        <div className="py-3 pl-2 pr-32"> {x.title}</div>
                    )}
                </div>
            ))}
        </div>
    );
};
