import { CoInnovationFilter, createFilterQueryString } from '../../../../shared';

import {
    ByFundResponse,
    FundsByPartnerResponse,
    FundsSummaryResponse,
    GetFundsParam,
    SummaryPartners,
    SummaryPartnersHistory,
    coInnovationFundListResponseParser,
    fundsByPartnerParser,
    fundsSummaryParser,
    summaryPartnersHistoryParser,
    summaryPartnersParser,
} from './domain';

import { agent, queryStringComposer } from '@shared/api';

const apiRoutes = {
    summaryPartnersMoney: '/api/coinnovationfunds/partners/money',
    summaryPartnersMoneyDetails: '/api/coinnovationfunds/partners/moneyDetails',
    summaryPartnersHistoryMoney: '/api/coinnovationfunds/partners/history-money',
    summaryByPartnerDetails: '/api/coinnovationfunds/partner-details',
    byFund: '/api/coinnovationfunds/by-fund',
};

export const getPartnersMoney = async (
    filter?: CoInnovationFilter,
    orderBy?: string,
    desc = false,
    isDownloading = false
) => {
    const queryString = createFilterQueryString({
        filter,
        sorting: { id: orderBy ? orderBy : '', desc },
        isDownloading,
    });
    const response = await agent.get<SummaryPartners>(`${apiRoutes.summaryPartnersMoney}${queryString}`);
    return response ? summaryPartnersParser.parseAsync(response) : null;
};

export const getPartnersHistoryMoney = async (
    filter?: CoInnovationFilter,
    orderBy?: string,
    desc = false,
    isDownloading = false
) => {
    const queryString = createFilterQueryString({
        filter,
        sorting: { id: orderBy ? orderBy : '', desc },
        isDownloading,
    });
    const response = await agent.get<SummaryPartnersHistory>(`${apiRoutes.summaryPartnersHistoryMoney}${queryString}`);
    return response ? summaryPartnersHistoryParser.parseAsync(response) : null;
};

type GetFundsSummaryParam = {
    filter?: CoInnovationFilter;
    orderBy?: string;
    desc: boolean;
    isDownloading: boolean;
};

export const getFundsSummary = async ({
    filter,
    orderBy,
    desc = false,
    isDownloading = false,
}: GetFundsSummaryParam) => {
    const queryString = createFilterQueryString({
        filter,
        sorting: { id: orderBy ? orderBy : '', desc },
        isDownloading,
    });

    const response = await agent.get<FundsSummaryResponse>(`${apiRoutes.summaryPartnersMoneyDetails}${queryString}`);

    return response ? fundsSummaryParser.parseAsync(response) : null;
};

export const getFundsByPartner = async ({
    filter,
    orderBy,
    desc = false,
    isDownloading = false,
}: GetFundsSummaryParam) => {
    const queryString = createFilterQueryString({
        filter,
        sorting: { id: orderBy ? orderBy : '', desc },
        isDownloading,
    });

    const response = await agent.get<FundsByPartnerResponse>(`${apiRoutes.summaryByPartnerDetails}${queryString}`);

    return response ? fundsByPartnerParser.parseAsync(response) : null;
};

export const getByFund = async ({
    pageIndex,
    pageSize,
    partnerIds,
    statusIds,
    years,
    validatorIds,
    sorting,
}: GetFundsParam) => {
    const queryParams = queryStringComposer()
        .addQueryStringValueFor('partnerIds', partnerIds)
        .addQueryStringValueFor('statusIds', statusIds)
        .addQueryStringValueFor('years', years)
        .addQueryStringValueFor('validatorIds', validatorIds)
        .addQueryStringValueFor('page', [pageIndex])
        .addQueryStringValueFor('pageSize', [pageSize])
        .addQueryStringSortingFor(sorting)
        .compose();

    const response = await agent.get<ByFundResponse>(`${apiRoutes.byFund}${queryParams}`);
    return response ? coInnovationFundListResponseParser.parseAsync(response) : null;
};
